import { languageConstants } from "./language.constants";

const initialState = {
	current: null
}

const language = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case languageConstants.SET_LANGUAGE_CONFIG:
			return { ...state, ...rest }
		default:
			return state
	}
}

export default language;
