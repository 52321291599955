import { branchConstants } from "./branch.constants";

const initialState = {
  selectionShow: true,
  current: null
}

const branch = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case branchConstants.SET_BRANCH_CONFIG:
      return { ...state, ...rest }
    default:
      return state
  }
}

export default branch;
