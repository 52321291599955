import { panelConstants } from "./panel.constants";

const initialState = {
	sidebarShow: true,
	sidebarUnfoldable: false
}

const panel = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case panelConstants.SET_CONFIG:
			return { ...state, ...rest }
		default:
			return state
	}
}

export default panel;
