import 'core-js'
import React from 'react'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App.jsx'
import reportWebVitals from './reportWebVitals.js'
import store from './stores/store.js'

createRoot(document.getElementById('root')).render(
	<Provider store={store}>
		<App />
	</Provider>,
)

reportWebVitals()
