import { toastConstants } from "./toast.constants";

const initialState = {
	toast: 0
}

const toast = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case toastConstants.SET_CONFIG:
			return { ...state, ...rest }
		default:
			return state
	}
}

export default toast;
