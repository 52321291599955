import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import branch from './branch/branch.reducer';
import language from './language/language.reducer';
import panel from './panel/panel.reducer';
import toast from './toast/toast.reducer';

const root = combineReducers({ panel, branch, language, toast });

const store = createStore(root, applyMiddleware(thunkMiddleware));

export default store;
