import { CSpinner } from '@coreui/react'
import React, { Component, Suspense } from 'react'
import { unstable_HistoryRouter as HistoryRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './components/private-route/PrivateRoute'
import { history } from './helpers/history'
import './scss/style.scss'

// Containers
const MainLayout = React.lazy(() => import('./components/coreui/MainLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/Login'))
const Logout = React.lazy(() => import('./views/auth/Logout'))
const Page404 = React.lazy(() => import('./views/error/Page404'))
const Page500 = React.lazy(() => import('./views/error/Page500'))

class App extends Component {
	render() {
		return (
			<HistoryRouter history={history}>
				<Suspense fallback={<div className='center'><CSpinner /></div>}>
					<Routes>
						<Route exact path="/login" name="Login Page" element={<Login />} />
						<Route exact path="/logout" name="Login Page" element={<Logout />} />
						<Route exact path="/404" name="Page 404" element={<Page404 />} />
						<Route exact path="/500" name="Page 500" element={<Page500 />} />
						<Route path="*" name="Home" element={<PrivateRoute component={MainLayout} />} />
					</Routes>
				</Suspense>
			</HistoryRouter>
		)
	}
}

export default App
